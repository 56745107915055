<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-08-01 11:36:23
 * @LastEditTime: 2024-05-11 16:47:11
 * @LastEditors: liujx@imyfone.cn
 * @FilePath: \cbsd:\hq\mfzj\src\views\layout\work\okr\evaluation\components\EvaluatePage.vue
-->
<template>
  <EvaluteTop tabSel="待评价" @changeValue="changeValue"></EvaluteTop>
  <EvaluateTemp :evaluteData="okrData"></EvaluateTemp>
</template>

<script>
import EvaluteTop from './EvaluteTop.vue'
import EvaluateTemp from './EvaluateTemp.vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { toRefs, reactive, computed } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { getsign } from '@/utils/tools.js'

export default {
  components: { EvaluteTop, EvaluateTemp },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const state = reactive({
      okrData: computed(() => store.state.okr.highEvaluteData),
      highComenting: computed(() => store.state.okr.highComenting),
      beforeUnloadTime: ''
    })

    const changeState = (status) => {
      console.log('切换状态', status)
      if (!state.okrData.id) return console.log('关闭页面')
      const P = new FormData()
      const { token, sign, timestamp } = getsign()
      P.append('token', token)
      P.append('sign', sign)
      P.append('timestamp', timestamp)
      P.append('id', state.okrData.id)
      P.append('evaluate_status', status)
      const url = process.env.VUE_APP_URL + 'api/okr/evaluate/status'
      navigator.sendBeacon(url, P)
      store.commit('okr/setHighComenting', false) // 修改评价中状态
    }

    onMounted(() => {
      window.addEventListener('visibilitychange', function () {
        if (state.highComenting && route.name === 'evaluatePage') {
          changeState(document.visibilityState === 'hidden' ? 1 : 2)
        }
      })
    })

    const changeValue = (tabName) => {
      router.push({ name: 'evaluation', params: { tabName } })
    }

    return {
      changeValue,
      ...toRefs(state)
    }
  }
}
</script>

<style>

</style>
